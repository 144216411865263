import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface Dialog {
    id: number;
    name: string;
    avatar: number;
}

interface Message {
    id: number;
    owner: number;
    receiveId: number;
    time: number;
    text: string;
}

let userId: number = 0;

export const Dialogs = () => {
    const [dialogs, setDialogs] = useState<Dialog[]>([]);
    const [selectedDialog, setSelectedDialog] = useState<Dialog | null>(null);
    const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        userId = parseInt(event.target.value);
        fetch(`http://localhost:3000/dialogs/${userId}`, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then(response => response.json())
            .then(data => setDialogs(data));
    };

    const handleDialogClick = (dialog: Dialog) => {
        setSelectedDialog(dialog);
        fetch(`http://localhost:3000/messages/${userId}/${dialog.id}`)
            .then(response => response.json())
            .then(data => setSelectedMessages(data));
    };

    return (
        <div className="dialogs-page">
            <h1>Dialogs</h1>
            <input type="text" placeholder="Enter User ID" onChange={handleInputChange} />
            <ul>
                {dialogs.map((dialog, index) => (
                    <li key={index} onClick={() => handleDialogClick(dialog)}>
                        {dialog.name}
                    </li>
                ))}
            </ul>
            {selectedDialog && (
                <div className="selected-dialog">
                    <h2>{selectedDialog.name}</h2>
                    <ul>
                        {selectedMessages.map((message, index) => (
                            <li key={index} className="message">
                                <span>
                                    {message.owner} ({new Date(message.time).toLocaleTimeString()}) - {message.text}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};